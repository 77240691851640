import React from 'react'
import styled from '@emotion/styled'
import Drawer from "./Header/Drawer";
import Logo from './Logo/logo.svg'

const HeaderWrapper = styled.header`
  display: flex;
  background: ${props => props.theme.colors.background};
  width: 100%;
  padding: 1.5em 0 1.5em 1.5em;
  border-bottom: 1px solid ${props => props.theme.colors.primary};
  max-width: ${props => props.theme.sizes.maxWidth};
  margin: 0 auto;
`

const Title = styled.div`
  display: inline-block;
  padding: 0.25em 0;
`

const Menu = () => {
  return (
    <HeaderWrapper>
      <Title>
        <img
          src={Logo}
          style={{ width: '180px' }}
          alt="logo"
        />
      </Title>
      <Drawer />
    </HeaderWrapper>
  )
}

export default Menu
