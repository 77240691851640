import { css } from '@emotion/core'

export const menuStyles = css`
  #nav-drawer {
    position: relative;
    z-index: 10;
  }

  /*チェックボックス等は非表示に*/
  .nav-unshown {
    display:none;
  }

  /*アイコンのスペース*/
  #nav-open {
    display: inline-block;
    width: 30px;
    height: 30px;
    vertical-align: middle;
    position: absolute;
    top: 50%;
    right: 44px;
    transform: translateY(50%);
    -webkit-transform: translateY(50%);
    -ms-transform: translateY(50%);
    cursor: pointer;
    z-index: 10;
    @media (max-width: 768px) {
      right: 3em;
    }
  }

  /*ハンバーガーアイコンをCSSだけで表現*/
  #nav-open span, #nav-open span:before, #nav-open span:after {
    position: absolute;
    height: 1px;/*線の太さ*/
    width: 25px;/*長さ*/
    background: #000000;
    display: block;
    content: '';
    cursor: pointer;
    z-index: 10;
  }
  #nav-open span:before {
    bottom: -8px;
  }
  #nav-open span:after {
    bottom: -16px;
  }

  /*閉じる用の透明カバー*/
  #nav-close {
    display: none;/*はじめは隠しておく*/
    position: fixed;
    z-index: 99;
    top: 0;/*全体に広がるように*/
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    transition: .3s ease-in-out;
  }

  /*中身*/
  #nav-content {
    overflow: auto;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9999;/*最前面に*/
    width: 90%;/*左側に隙間を作る（閉じるカバーを表示）*/
    max-width: 260px;/*最大幅（調整してください）*/
    height: 100%;
    background: #fff;/*背景色*/
    transition: .3s ease-in-out;/*滑らかに表示*/
    -webkit-transform: translateX(-105%);
    transform: translateX(105%);/*右に隠しておく*/
    border-left: 1px solid #121212;
    padding: 2em 1.5em 2em ;
  }

  /*チェックが入ったらもろもろ表示*/
  #nav-input:checked ~ #nav-close {
    display: block;/*カバーを表示*/
  }

  #nav-input:checked ~ #nav-content {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);/*中身を表示（左へスライド）*/
  }
`
