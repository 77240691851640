import React from 'react'
import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faFacebookF } from '@fortawesome/free-brands-svg-icons'

const Wrapper = styled.footer`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.maxWidth};
  border-top: 1px solid ${props => props.theme.colors.primary};
`

const List = styled.ul`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    justify-content: start;
  }
  align-items: flex-end;
  width: 100%;
  padding: 2em 0 2em;
  margin: 0 1.5em;
`

const ItemRight = styled.li`
  display: inline-block;
  padding: 0.25em 0;
  width: 40%;
  margin-right: 1em;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    width: auto;
  }
`

const ItemLeft = styled.li`
  display: inline-block;
  padding: 0.5em 0 0.25em;
  width: 100%;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    width: auto;
  }
  h5 {
    font-size: 1.2em;
    text-align: end;
  }
  p {
    font-size: 0.85em;
    text-align: end;
  }
  a {
    font-weight: 600;
    transition: all 0.2s;
    color: ${props => props.theme.colors.text};
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
    &:visited {
      color: ${props => props.theme.colors.text};
   }
  }
  svg {
    margin: 1em 0 0 1.2em;
    font-size: 1.5em;
    display: inline-block;
    text-align: right;
  }
  svg.first {
    margin-left: 2.5em;
  }
`

const Footer = () => (
  <Wrapper>
    <List>
      <ItemRight>
        <img
          src="https://images.ctfassets.net/dudd6heorg5u/3ZZgBAPwy7pomxtm4YBRjt/f671fc58b59da6c106c1b4e54a0bb624/WALLA_logo_footer.svg"
          style={{ width: '100px' }}
          alt="logo_footer"
        />
      </ItemRight>
      <ItemLeft>
        <p>
          <h5><a
            href="https://twitter.com/walla_space"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faTwitter} />
          </a>{' '}
            <a
              href="https://www.facebook.com/walla.space/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a><br />{' '}<br /></h5>
          <h5>© 2020 WALLA</h5>
          {' '}<br />
          東京都小平市仲町615-29{' '}
          <a
            href="https://g.page/walla-space?share"
            target="_blank"
            rel="noopener noreferrer"
          >
            Map
          </a>
        </p>
      </ItemLeft>
    </List>
  </Wrapper>
)

export default Footer
