import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { useSiteMetadata } from '../../hooks/use-site-metadata'
import { menuStyles } from "../../styles/menuStyles";
import SlashMenu from '../Slash/SlashMenu'
import Logo from '../Logo/wallaboard_logo.svg'

const FixedWrapper = styled.nav`
  width: 100vw;
  max-width: ${props => props.theme.sizes.maxWidth};
  margin: 0 auto;
  padding: 0 1.5em;
  position: fixed;
  z-index: 1;

  li {
    display: block;
    position: relative;
    margin-left: 1em;
    margin-bottom: 1.2em;
  }

  a {
    text-decoration: none;
    color: #121212;
    font-weight: 600;
    transition: all 0.2s;
    &:hover {
      color:  #a9a9a9;
    }
  }
`

const CloseButton = styled.label`
    width: 26px;
    height: 24px;
    display: inline-block;
    cursor: pointer;
  &:before, &:after {
    height: 1px;/*線の太さ*/
    width: 25px;/*長さ*/
    background: #121212;
    display: block;
    content: '';
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 12px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
  }
  &:before {
    transform: rotate(-51.6deg);
  }
  &:after {
    transform: rotate(-128.4deg)
  }
`

const MenuContent = styled.div`
  margin-top: 2.5em;
`

const MenuList = styled.ul`
  display: block;
`

const MenuItem = styled.li`
  font-size: 1.5em;
  display: block;
`

const activeLinkStyle = {
  color: '#a9a9a9'
}

const P = styled.p`
  font-weight: 600;
  font-size: 0.8em;
  margin-top: 1.5em;
  &:before {
    position: absolute;
    left: -32px;
    top: 1em;
    height: 1px;/*線の太さ*/
    width: 48px;/*長さ*/
    background: #121212;
    display: block;
    content: '';
    transform: rotate(-51.6deg);
  }
`

const LogoImg = styled.img`
  width: 92px;
`

const LogoText = styled.p`
  display: block;
  font-size: 0.6em;
  margin-top: 0.5em;

`

const Drawer = () => {
  const { menuLinks } = useSiteMetadata()

  return (
    <StaticQuery
      query={graphql`
        query {
          allContentfulActivity(
            filter: {status: {eq: "upcoming"}}
          ) {
            edges {
              node {
                slug
              }
            }
          }
        }
      `}
      render={data => (
        <FixedWrapper>
          <div id="nav-drawer" css={menuStyles}>
            <input id="nav-input" type="checkbox" class="nav-unshown" />
            <label id="nav-open" for="nav-input">
              <P>Menu</P>
            </label>
            <label class="nav-unshown" id="nav-close" for="nav-input"></label>
            <div id="nav-content">
              <div style={{ position: 'relative' }}>
                <CloseButton for="nav-input"></CloseButton>
              </div>
              <MenuContent>
                <MenuList>
                  {(data.allContentfulActivity.edges.length === 0) ? (
                    <>
                      {menuLinks.filter(link => (link.name !== 'Upcoming')).map(link => (
                        <MenuItem key={link.name}>
                          <Link to={link.slug} activeStyle={{ activeLinkStyle }}>
                            {link.name}
                          </Link>
                        </MenuItem>
                      ))}
                    </>
                  ) : (
                    <>
                      {menuLinks.map(link => (
                        <MenuItem key={link.name}>
                          <Link to={link.slug} activeStyle={{ activeLinkStyle }}>
                            {link.name}
                          </Link>
                        </MenuItem>
                      ))}
                    </>
                  )}
                  <MenuItem key="Shop">
                    <Link to="https://suzuri.jp/WALLA" activeStyle={{ activeLinkStyle }} target="_blank">
                      Shop
                    </Link>
                  </MenuItem>
                  <MenuItem key="Slash">
                    <SlashMenu />
                  </MenuItem>
                  <MenuItem>
                    <div>
                      <Link to="https://wallaboard.jp/" activeStyle={{ activeLinkStyle }} target="_blank">
                        <LogoImg
                          src={Logo}
                          alt="logo"
                        />
                        <LogoText>WALLA Board →</LogoText>
                      </Link>
                    </div>
                  </MenuItem>
                </MenuList>
              </MenuContent>
            </div>
          </div>
        </FixedWrapper>
      )}
    />
  )
}

export default Drawer
