import React from 'react'
import styled from '@emotion/styled'

// -------------------------------------------------
// アクセントの／線
// -------------------------------------------------

const Slash = styled.div`
  position: relative;
  &:before {
    position: absolute;
    top: 47px;
    left: -23px;
    height: 1px;/*線の太さ*/
    width: 120px;/*長さ*/
    background: #121212;
    display: block;
    content: '';
    transform: rotate(-51.6deg);
  }
  &:after {
    content: "";
    display: block;
    padding-top: 52%;
}
`

const SlashMenu = props => {
  return <Slash />
}

export default SlashMenu
